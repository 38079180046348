import { useContext, useEffect, useRef, useState } from "react";
import { appContext } from "../App";
import Alert from "../component/alert/alert";
import AppConfig from "../app-config";
import TabCompany from "./tab/tab-company";
import TabProperty from "./tab/tab-property";
import AsideFAQ from "./faq/aside-faq";
import Settings from "./aside/synd-settings";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import store, { dashboardInitialState } from "../store";

const Dashboard = () => {
    let dispatch = useDispatch();
    let errorCount = 0;
    // RAUL elements
    const companyTabRef = useRef();
    const propertyTabRef = useRef();
    const tabRef = useRef();

    // http service from context
    const { http } = useContext(appContext);

    // create alert state to show errors
    const [alert, setAlert] = useState({ title: null, type: null, message: null });

    // using this in tab handling to only display settings button when in property tab
    const [activeTab, setActiveTab] = useState('company');

    // initial data
    const [dashboardData, setDashboardData] = useState(null);
    const [propertyData, setPropertyData] = useState(null);
    const [ilsFaqData, setIlsFaqData] = useState(null);
    const [otherFaqData, setOtherFaqData] = useState(null);
    const [channelList, setChannelList] = useState(null);
    const [totalLeadChannels, setTotalLeadChannels] = useState(null);
    const [companyIlsFaqData, setcompanyIlsFaqData] = useState(null);
    const [propertyIlsFaqData, setPropertyIlsFaqData] = useState(null);
    const [isFromCompanyTab, setIsFromCompanyTab] = useState(false);
    const [subscriptionEnable, setSubscriptionEnable] = useState(false);
    const dashboard = useSelector((state) => state.dashboard);
    const tabEl = tabRef.current;
    const toggleTab = (name) => {
        tabRef.current.activeTab = name;
        setActiveTab(name);
        setIsFromCompanyTab(isFromCompanyTab=>!isFromCompanyTab);
        if (companyTabRef.current && propertyTabRef.current) {
            if (name === 'company') {
                companyTabRef.current.style.display = 'block';
                propertyTabRef.current.style.display = 'none';
                //setDashboardData(store.getState().dashboard);
            } else {
                companyTabRef.current.style.display = 'none';
                propertyTabRef.current.style.display = 'block';
            }
        }
    };

    const getProperty = (propertyList, pKey) => {
        let key = pKey;
        if (pKey === null) {
            key = http.params.pkey;
        }
        return propertyList.find((p) => p.pkey === key);
    };

    const getData = (propertyId) =>{
        var url =  AppConfig.uri.leadChannels.replace('{id}',propertyId);
        const chanelList = http.callApi('GET', url);
        Promise.all([chanelList])
                .then((response) => {
                  setTotalLeadChannels(response[0]);
                  setChannelList(response[0].assignedLeadChannels);
                })
                .catch((error) => {
                    errorCount++;
                })
    }

    const changeProperty = (pKey) => {
        if(dashboardData && dashboardData.propertyList) {
            const prop = getProperty(dashboardData.propertyList, pKey);
            if(prop) {
                getData(prop.propertyId)
                setPropertyData(prop);
                toggleTab('property');
            }
        }
    };

    const changeDashboardProperty = (pKey) => {
        if(document.referrer != '') 
            window.top.location.href= document.referrer+'syndicationPerformance/'+pKey; 
        else  
            changeProperty(pKey); 
    };
    
    useEffect(()=>{
      setDashboardData(dashboard);
    },[dashboard]);

    useEffect(()=>{
        if(activeTab === 'company'){
            setIlsFaqData(companyIlsFaqData);
        } else {
            if(propertyData) {
                setIlsFaqData(propertyIlsFaqData[propertyData.propertyId]); 
            }
        }
    },[activeTab]);

    useEffect(()=>{
       
        let propData = null;
        if(dashboardData != null) {
            var url =  AppConfig.uri.emailSubscription
            http.callApi('GET', url)
            .then((response) => {
                setSubscriptionEnable(response);
            }).catch((message) => {
                console.log('Error occurred while emailSubscription. '+message); 
            });

            let pkey = http.params.pkey;
            if(pkey) {
                const prop = getProperty(dashboardData.propertyList, pkey);
                if(prop) {
                    if (tabEl) {
                        tabEl.tabs = [{ label: 'Company Syndication', name: 'company' }, { label: 'Property Syndication', name: 'property' }];
                        toggleTab('property');
                        setPropertyData(prop);
                        getData(prop.propertyId);
                        propData = prop;
                    }
                } else {
                    if (tabEl) {
                        tabEl.tabs = [{ label: 'Company Syndication', name: 'company' }];
                        toggleTab('company');
                    }
                }  
            } else {
                if (tabEl) {
                    tabEl.tabs = [{ label: 'Company Syndication', name: 'company' }, { label: 'Property Syndication', name: 'property' }];
                    toggleTab('company');
                }
                setPropertyData(dashboardData.propertyList[0]);
                propData = dashboardData.propertyList[0];
            }

        }
        
        tabEl?.addEventListener('raulTabChange', (e) => {
            toggleTab(e.detail);
            if(propData && e.detail === 'property') {
                changeDashboardProperty(propData.pkey);
            }
        });
    },[dashboardData])
   
    useEffect(() => {
        // alert handling
        const showAlert = (message, title, type) => {
            setAlert({ message: message, title: title, type: type, hide: showAlert });
        };

        const sortLists = (data) => {
            // sort channel list by name
            for (var property of data.propertyList) {
                if (property.channelList && property.channelList.length > 1) {
                    property.channelList.sort((a, b) => a.leadChannelName.localeCompare(b.leadChannelName));
                }
            }
            return data;
        };
       

        const prepareILSFaqsByProperty=(dashboardData,ilsfaqs) => {
            let faqsByProperty ={};
            let companyfaqs = [];
            _.each(dashboardData.propertyList, function (item) {
                let faqs = [];
                _.each(item.channelList, function (channel) {
                    let leadChannel = _.find(ilsfaqs, {leadChannelId: channel.leadChannelId});
                    if(leadChannel) {
                        faqs.push(leadChannel);
                    }
                })
                if(faqs.length > 0) {
                    faqsByProperty[item.propertyId] = faqs;
                    companyfaqs.push(...faqs);
                }
            })
            companyfaqs =  _.uniqWith(companyfaqs, _.isEqual).sort((a, b) => a.ilsName.localeCompare(b.ilsName));
            setcompanyIlsFaqData(companyfaqs);
            setIlsFaqData(companyfaqs);
            setPropertyIlsFaqData(faqsByProperty);
        }

        // --- POPULATE DATA ---
        http.callApi('GET', AppConfig.uri.dashboard, {pageNumber: 0, pageSize: 50})
            .then((response) => {
                const dashData = sortLists(response);
                if(dashData && dashData.propertyList) {
                    dashData.propertyList = _.orderBy(dashData.propertyList,[list=>list['propertyName'].toLowerCase()],'asc');
                }
                setDashboardData(dashData);
                dispatch(dashboardInitialState(dashData));
                setIlsFaqData(store.getState().ilsFaqs);
                setOtherFaqData(store.getState().otherFaqs);
                prepareILSFaqsByProperty(dashData,store.getState().ilsFaqs);
            })
            .catch((error) => {
                errorCount++;
                setDashboardData(null);
                showAlert(error);
            })
    }, [http]);

    return (
        <main>
            <Alert params={alert} />
            {errorCount === 0 ? 
            <>
                <div class="r-bg-gray-lightest r-p-3 r-pt-8 r-pb-0">
                    <h1 class="font-24 r-font-extrabold  r-text-black">
                        { activeTab === 'property' && <Settings property={propertyData} /> }
                        { ilsFaqData && <AsideFAQ ilsFaq={ilsFaqData} otherFaq={otherFaqData}/> }
                        Syndication Performance
                    </h1>
                </div>
                    <div>
                        <raul-tabs ref={tabRef} class="r-bg-gray-lightest r-mb-8 r-pt-5"></raul-tabs>
                        <div className="dashboard r-pt-0">
                            <raul-tab-pane name="company" class="tabs-pane" ref={companyTabRef}>
                                {
                                    dashboardData &&
                                    <div><TabCompany dashboardData={dashboardData} subscriptionEnable={subscriptionEnable}
                                        changeDashboardProperty={changeDashboardProperty} /></div>
                                }
                            </raul-tab-pane>

                            <raul-tab-pane name="property" class="tabs-pane" ref={propertyTabRef}>
                                {
                                    propertyData &&
                                    <div><TabProperty isFromCompanyTab={isFromCompanyTab} propertyData={propertyData} companyName={dashboardData.companyName} mcUrl={dashboardData.mcUrl} channelList={channelList} totalLeadChannels={totalLeadChannels} subscriptionEnable={subscriptionEnable} /></div>
                                }
                            </raul-tab-pane>
                        </div>
                    </div> 
                </>: "" }
        </main>
    );
           
};

export default Dashboard;