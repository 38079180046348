import { useEffect, useRef, useState, useContext } from "react";
import _ from "lodash";
import AppConfig from "../../app-config";
import { loaderContext, appContext } from "../../App";
import { exportMissingFileds, syndDataImprovementGraphicExportAsPdf } from "../../service/utils";

const MissingData = (props) => {
    const imageRef = useRef();
    // count properties with incomplete data
    let missingPropertiesCount = 0;
    if (props && props.propertyList) {
        for (var i=0; i < props.propertyList.length; i++) {
            if (props.propertyList[i].completenessScore
                && props.propertyList[i].completenessScore.missingFieldList
                && props.propertyList[i].completenessScore.missingFieldList.length > 0)
            {
                missingPropertiesCount++;
            }
        }
    }

    const getMissingFieldCount = (property) => {
        if (property && property.completenessScore && property.completenessScore.missingFieldList) {
            return property.completenessScore.missingFieldList.length;
        }
        return 0;
    };
    const loader  = useContext(loaderContext);
    const { http } = useContext(appContext);
    const asideRef = useRef();
    const btnRef = useRef();
    const missingFieldsRef = useRef();
    const closeRef = useRef();
    const helpAside = useRef();
    const tipsTricksAside = useRef();
    const tipsTricksCloseRef = useRef();
    const [filteredList, setFilteredList] = useState([]);
    const [sentEmailProperties, setSentEmailProperties] = useState([]);
    const emailRef = useRef();
    const emailAsideRef = useRef();
    const [selectedEmailProperties, setSelectedEmailProperties] = useState([]);
    const [emailSendBtn, setEmailSendBtn] = useState(true);
    const missingFieldLinks = {
        other: props.mcUrl+"property/{pkey}/description",
        marketingPhotos: props.mcUrl+"property/{pkey}/gallery-view",
        propAndFpAmenity: props.mcUrl+"property/{pkey}/amenity/view",
        fpDiagramPhotosVTours: props.mcUrl+"floorplans/{pkey}"
    }
    const searchSentEmailRef = useRef();
    const searchMissingRef  = useRef();
    const [message,setMessage] = useState("");
    const [isSaved,setIsSaved] = useState(false);

    useEffect(() => {
        var activeEmailPropList = _.filter(props.propertyList, function (property) {
            return !property.isDisabled;
        })
        if(selectedEmailProperties.length === activeEmailPropList.length){
            document.getElementsByName('selectAllemailNotification')[0].checked = true;
        }
        setEmailSendBtn(selectedEmailProperties.length === 0);
    },[selectedEmailProperties]);

    useEffect(() => {
        btnRef.current.addEventListener('click', function() {
            asideRef.current.open();
            setFilteredList(props.propertyList);
            setIsSaved(false);
            searchMissingRef.current.value = "";
            setTimeout(()=>{
                document.getElementById("missingFileds").scrollTop = 0;
            }, 1);
        });

        closeRef.current.addEventListener('click', function() {
            asideRef.current.close();
        });

        helpAside.current.addEventListener('click', function() {
            setIsSaved(false);
            tipsTricksAside.current.open();
        });

        tipsTricksCloseRef.current.addEventListener('click', function() {
            tipsTricksAside.current.close();
        });

        emailRef.current.addEventListener('click',function() {
            document.getElementsByName('selectAllemailNotification')[0].checked = false;
            document.querySelectorAll('input[name=emailNotification]').forEach(el => el.checked = false);
            searchSentEmailRef.current.value = "";
            setEmailSendBtn(true);
            setIsSaved(false);
            setSelectedEmailProperties([]);
            setSentEmailProperties(props.propertyList);
            emailAsideRef.current.open();
        });  
    }, []);

    const handleSearchChange = (e) => {
        const searchValue = e.target.value;

        if (searchValue && searchValue.length > 0) {
            setFilteredList(applyFilter(searchValue));
        } else {
            setFilteredList(props.propertyList);
        }
    };

    const handleSearchByProperty = (e) => {
        const searchValue = e.target.value;

        if (searchValue && searchValue.length > 0) {
            setSentEmailProperties(applyFilter(searchValue));
        } else {
            setSentEmailProperties(props.propertyList);
        }
    }

    const applyFilter  = (searchValue) => {
        return props.propertyList.filter((property) => {
            const searchMatch = !searchValue || property.propertyName.toLowerCase().includes(searchValue.toLowerCase());
            return searchMatch;
        });
    };

    const missingFieldUrl = (pkey, name) => {
        var url = "";
        if (["Active Floorplan","Floorplan Photo", "Floorplan Diagram", "Floorplan Video"].includes(name))
            url = missingFieldLinks.fpDiagramPhotosVTours.replace('{pkey}', pkey);
        else if (["Floorplan Amenity" , "Property Amenity"].includes(name))
            url = missingFieldLinks.propAndFpAmenity.replace('{pkey}', pkey);
        else if (name === "Property Marketing Photos")
            url = missingFieldLinks.marketingPhotos.replace('{pkey}', pkey);
        else
            url = missingFieldLinks.other.replace('{pkey}', pkey);
        window.open(url, '_blank', 'noopener');
    };

    const isSelectEmail = function(e, property) {
        if(e.target.checked){
            let requestObject = {
                propertyId:property.propertyId,
                score:property.completenessScore?.propertyScore
            };
            if(selectedEmailProperties.length > 0){
                setSelectedEmailProperties([...selectedEmailProperties, requestObject]);
            }else{
                setSelectedEmailProperties([requestObject]);
            }
        } else{
            var uncheckedProp = _.find(selectedEmailProperties, {propertyId:property.propertyId});
            setSelectedEmailProperties(_.without(selectedEmailProperties, uncheckedProp));
            document.getElementsByName('selectAllemailNotification')[0].checked = false;
        }
    }

    const selectAllEmails = function(e) {
        if(e.target.checked){
            document.querySelectorAll('input[name=emailNotification]').forEach(el => el.checked = el.getAttributeNames().includes('disabled') ? false : true );
            var filterPropList = _.filter(props.propertyList, function (property) {
                    return !property.isDisabled;
                }).map(property => (      
                    {
                        propertyId:property.propertyId,
                        score:property.completenessScore.propertyScore
                    }
                ));
            setSelectedEmailProperties(filterPropList);
        } else{
            setSelectedEmailProperties([]);
            document.querySelectorAll('input[name=emailNotification]').forEach(el => el.checked = false);
        }
    }

    const closeSendEmailAside = function() {
        emailAsideRef.current.close();
    }

    const sentEmailNotification = () => {
        var url =  AppConfig.uri.sendEmailNotification;
        http.callApi('POST', url, selectedEmailProperties)
        .then((response) => {
            setIsSaved(true);
            setMessage('Email sent successfully to ('+ selectedEmailProperties.length +') properties.');
            _.each(response, function(obj){
                _.filter(props.propertyList, function (property) {
                     if(property.propertyId === obj.propertyId){
                        property.lastEmailSent = obj.lastSent;
                        property.isDisabled = true;
                     };
                });
            })
            
            emailAsideRef.current.close();
        }).catch((error) => {
            setIsSaved(true);
            setMessage("Error while Email sent not successfully to properties..");
            emailAsideRef.current.close();
        });
        
    }

    return (
        <div class="missing-data-container">
            <raul-container>
                <div class="r-flex">
                    <raul-icon icon="alert" class="r-text-destructive r-inline-block r-pr-3" />
                    <div class="content-text r-w-4/5">
                        <p class="r-text-lg r-font-bold">Missing Syndication Data</p>
                        <p class="r-text-md">You have ({missingPropertiesCount}) properties missing important syndication data fields.</p>
                    </div>
                    <div class="r-w-1/5">
                        <raul-button variant="control" size="small" class="r-float-right" ref={btnRef}>Update Missing Data</raul-button>
                    </div>
                </div>
            </raul-container>

            <raul-aside id="missing-data-aside" size="medium" ref={asideRef}>   
                <raul-aside-header>            
                    <div class="r-pb-2">
                        <raul-aside-title class="r-font-bold">
                            <div class="r-flex">
                                <div class="r-w-2/3">
                                    <p class="r-mb-1 r-text-sm">{props.companyName}</p>
                                    <p class="r-text-black">Missing Syndication Data</p>
                                </div>
                                <div class="r-w-1/2">
                                    <raul-button variant = "control" size = "small" id="missingExport" class="r-float-right r-mt-0" onClick={() => exportMissingFileds(props, 'company')} >
                                        <raul-icon icon="download-bottom" kind ="consumer" class="r-icon-sm"></raul-icon>
                                        Export
                                    </raul-button>
                                    <div class="r-float-right r-text-primary r-mr-2 r-text-sm r-p-2 nested-aside-show-button r-cursor-pointer" ref={helpAside}>
                                          <raul-icon icon="help-center" kind="resource" class="r-text-md r-mr-2"></raul-icon>
                                         Helpful Tips
                                    </div>                                  
                                </div>
                            </div>
                        </raul-aside-title>
                    </div>
                </raul-aside-header>
                <raul-aside-body class="r-pr-1">
                    <div class="r-mb-4 r-pr-5">
                        <raul-input type="search">
                            <input type="text" placeholder="Search for a Property" ref={searchMissingRef} onChange={handleSearchChange}/>
                        </raul-input>
                    </div>
                    <div>
                        {
                            !props.subscriptionEnable &&
                            <p class="r-font-roboto r-text-sm r-pt-3 r-mb-5">To subscribe to Syndication Data email notifications, select the user profile icon in the top right corner of the page header and navigate to Email Preferences.</p>
                        }
                    </div>
                    <div class="r-flex r-w-11/12">
                            <div  class="r-flex r-mb-3">
                                <raul-button variant="control" class="r-pb-2" size="small" ref={emailRef} disabled = {!props.subscriptionEnable}>Send Email Notification</raul-button>
                                <raul-tooltip class="r-text r-cursor-pointer  r-text-black r-pb-2" text='<p>Select the properties you would like to include in the email notification. <br/><br/> If an email was sent in the last 24 hours, the option to re-send will be disabled for that property</p>' placement="bottom">
                                    <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                                </raul-tooltip>
                                {
                                    !props.subscriptionEnable && 
                                    <div className="missing-email-alert r-pl-4">
                                    <raul-alert class="r-font-bold"
                                        content="No Email Subscribers (See Above)"
                                        rounded
                                        variant="warning"
                                    ></raul-alert>
                                    </div>
                                }
                            </div>
                        
                            {isSaved && 
                                <div class="missing-email-alert r-pl-4">
                                    <raul-alert class="r-font-bold" content={message} rounded variant={message.includes("Error") ? "danger" : "success"}/>
                                </div>
                            }
                            
                    </div>                    
                    
                    <p></p> 
                    <div class="aside-missing-data" ref={missingFieldsRef} id="missingFileds">
                        {filteredList.map((property,index) => (
                            <div class="r-border-b r-border-gray-medium r-pt-2 r-pb-2" key={index}>
                                <div class="r-flex">
                                    <raul-icon icon="alert" class="r-text-destructive r-text-hero r-pr-2"></raul-icon>
                                    <div class="content-text r-w-11/12">
                                        <p class="r-text-md r-font-semibold">{property.propertyName}</p>
                                        <div class="r-flex">
                                            <div class="r-w-1/2">
                                                <p class="r-text-md">You have ({ getMissingFieldCount(property) }) missing fields.</p>
                                            </div>
                                            <div class="r-w-1/2">
                                                <p>Last Email Sent : {property.lastEmailSent}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="aside-missing-data-margin">
                                    <p class="r-text-md r-mb-2">The following fields are missing.</p>
                                    <ul class="two-column-list"> { property.completenessScore.missingFieldList.map((item,index) =>(
                                        <li class="r-text-primary" key={index}><div className="r-cursor-pointer" onClick={() => missingFieldUrl(property.pkey, item)} target="_blank">{item}</div></li>
                                    ))}</ul>   
                                </div>
                            </div>
                        ))}
                    </div>
                </raul-aside-body>
                <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-aside-actions>
                            <raul-button class="close-aside" variant="primary" id="missing-data-aside-close" ref={closeRef}>
                                Close 
                            </raul-button>           
                        </raul-aside-actions>
                    </raul-aside-actions>
                </raul-aside-footer>
                <raul-aside id="tips-tricks-aside" slot="secondary-aside" size="large" ref={tipsTricksAside}>
                    <raul-aside-header class="r-bg-gray-lightest">
                        <raul-aside-title><span>Tips & Tricks</span>
                        <raul-button variant = "control" size = "small" id="tipsExport" class="r-float-right r-mt-0" onClick={()=>syndDataImprovementGraphicExportAsPdf(imageRef, loader)} >
                            <raul-icon icon="download-bottom" kind ="consumer" class="r-icon-sm"></raul-icon>
                            Export
                        </raul-button>
                    </raul-aside-title>
        
                    </raul-aside-header>

                    <raul-aside-body class="r-pl-0">
                        <img src="images/SyndDataImprovementGraphic.png" alt="BigCo Inc. logo" ref={imageRef} />
                    </raul-aside-body>

                    <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-button class="nested-aside-close" variant="secondary" id="tips-tricks-aside-close" ref={tipsTricksCloseRef}>
                        Close
                        </raul-button>
                    </raul-aside-actions>
                    </raul-aside-footer>
                </raul-aside>

                <raul-aside id="email-aside" slot="secondary-aside" size="medium" ref={emailAsideRef}>
                    <raul-aside-header>                  
                        <raul-aside-title class="r-font-bold">
                            <div class="r-w-full">
                                <p class="r-mb-1 r-text-sm">{props.companyName}</p>
                                <p class="r-text-black">Email Notifications</p>
                            </div>
                        </raul-aside-title>
                        
                        <div class="r-pt-4">
                            <p class="r-pb-4 r-text-sm">Select which properties you would like to send an email notififcation to regarding their missing syndication data.</p>
                            <p class="r-pb-2 r-text-sm">If a property is disabled, an email has already been sent in the last 24 hours.</p>
                        </div>
                    </raul-aside-header>
                    <raul-aside-body>
                        <raul-input type="search" class="r-mb-3">
                            <input type="text" placeholder="Search for a Property" ref={searchSentEmailRef} onChange={handleSearchByProperty}/>
                        </raul-input>

                        <div class="even-odd r-flex r-pt-3 r-pb-3 r-pl-3 email-aside-border">
                            <div class="r-w-1/12">
                                <raul-checkbox>
                                    <input type="checkbox" name='selectAllemailNotification' onClick={(event) => selectAllEmails(event)}/>
                                </raul-checkbox>
                            </div>
                            <div class="r-w-11/12">
                                <span class="r-block r-font-semibold r-text-md">Select All</span>
                                <span class="r-block r-text-md"></span>    
                            </div>
                        </div>
                    
                        { sentEmailProperties.map((property, index) => 
                            <div class="even-odd r-flex r-pt-3 r-pb-3 r-pl-3 email-aside-border" key={index}>
                                <div class="r-w-1/12">
                                    <raul-checkbox>
                                        <input type="checkbox" name='emailNotification' onClick={(event) => isSelectEmail(event, property)} disabled={property.isDisabled}/>
                                    </raul-checkbox>
                                </div>
                                <div class="r-w-6/12">
                                    <span class="r-block r-font-semibold r-text-md">{property.propertyName}</span>
                                </div>
                                <div class="r-w-5/12">
                                    <span class="r-block r-text-sm">Last Email Sent : {property.lastEmailSent}</span>    
                                </div>
                            </div>
                         )}
                    </raul-aside-body>
                    <raul-aside-footer>
                        <raul-aside-actions>
                            <raul-button variant="secondary" id="send-email-notification-close" onClick={() => closeSendEmailAside()}>
                            Close
                            </raul-button>
                            <raul-button class="r-ml-2" disabled={emailSendBtn} variant="primary" id="send-email-notification" onClick={() => sentEmailNotification()}>
                            Send Email Notification
                            </raul-button>
                        </raul-aside-actions>
                    </raul-aside-footer>
                </raul-aside>

            </raul-aside>
            
        </div>    
    ) 
};

export default MissingData;