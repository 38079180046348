import React, { useContext, useEffect, useRef, useState } from 'react';
import { appContext, loaderContext } from "../../App";
import AppConfig from "../../app-config";
import _ from "lodash";
import { syndDataImprovementGraphicExportAsPdf } from '../../service/utils';

const TableCompany = (props) => {

    // http service from context
    const { http } = useContext(appContext);
    const loader  = useContext(loaderContext);

    const asideRef = useRef();
    const closeBtnRef = useRef();
    const imageRef = useRef();
    const tipsImageRef = useRef();
    const readyRef = useRef();
    const tipsTricksAside = useRef();
    const tipsTricksCloseRef = useRef();
    const pageRef = useRef();
    const [propertyList, setPropertyList] = useState([]);
    const [order, setOrder] = useState('asc');
    const [sortBy, setSortBy] = useState('propertyName');
    const [asideData, setAsideData] = useState(null);
    
    const getPage = (lists, from, to) => {
        if(sortBy !== 'channelList'){
            lists = _.orderBy(lists,[list=>list[sortBy].toLowerCase()],order);
        } else {
            lists = _.orderBy(lists,sortBy,order);
        }
        return lists.slice(from - 1, to);
    };

    const openEdit = (property) => {
        setAsideData(property);
        if (property) {
            imageRef.current.checked = (property.syndicateMedia === "true");
            readyRef.current.checked = (property.syndicationReady === "true");
            asideRef.current.open();
        }
    };


    const companySort = (field) => {
        if(field === sortBy) {
            setOrder(order === 'asc' ?'desc':'asc');
        } else {
            setSortBy(field);
            setOrder('asc');
        }
    }

    const saveData = (property) => {
        const data = {
            propertyKey: property.pkey,
            syndicateMedia: "" + imageRef.current.checked,
            syndicationReady: "" + readyRef.current.checked
        };
        http.callApi('PUT', AppConfig.uri.updateProduct, data)
            .then((response) => {
                // update property with saved information
                property.syndicateMedia = response.syndicateMedia;
                property.syndicationReady = response.syndicationReady;
                asideRef.current.close();
            });
    };

    const helpfultips = function() {
        tipsTricksAside?.current.open();
    }

    useEffect(() => {
        setPropertyList(getPage(props.propertyList, 1, 10))
        // pagination
        pageRef.current.addEventListener('pagingChange', (e) => {
            setPropertyList(getPage(props.propertyList, e.detail.startRow, e.detail.endRow));
        });
        if(pageRef.current) {
            pageRef.current.rowsPerPage = "10";
            pageRef.current.currentPage = "1";
        }
        // aside control
        closeBtnRef.current.addEventListener('click', () => asideRef.current.close());
    
        tipsTricksCloseRef?.current.addEventListener('click', function() {
            tipsTricksAside?.current.close();
        });

    }, [props]);

    useEffect(() => {
        let startRow = ((pageRef.current.currentPage -1) * pageRef.current.rowsPerPage ) + 1;
        let endRow = pageRef.current.rowsPerPage * pageRef.current.currentPage > props.propertyList.length ? props.propertyList.length : pageRef.current.rowsPerPage * pageRef.current.currentPage;
        setPropertyList(getPage(props.propertyList, startRow, endRow));
    }, [order,sortBy]);

    return (
        <div>
            <raul-aside id="small-aside" size="small" ref={asideRef}>
                <raul-aside-header>
                    <raul-aside-sub-title class="r-font-semibold r-text-sm">{asideData?.propertyName}</raul-aside-sub-title>
                    <raul-aside-title  class="r-mt-1 r-text-black">Edit Configuration
                        <a href='http://onesitehelp.realpage.com/?env=on&vr=40&ScrVer=350&pg=/mc/company/companyId/syndication/edit' target='_blank'>
                            <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                        </a>
                    </raul-aside-title>
                </raul-aside-header>

                <raul-aside-body>
                    <div class="r-font-hairline">
                        <raul-checkbox label-text="Sending Images" class="r-mb-4">
                            <input type="checkbox" ref={imageRef} />
                        </raul-checkbox>
                    </div>
                    <div class="r-font-hairline">
                        <raul-checkbox label-text="Send Ready" class="r-mb-4">
                            <input type="checkbox" ref={readyRef}/>
                        </raul-checkbox>
                    </div>
                </raul-aside-body>

                <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-button class="close-aside" variant="secondary" id="small-aside-close" ref={closeBtnRef}>
                        Close
                        </raul-button>
                        <raul-button class="close-aside r-ml-2" variant="primary" id="small-aside-close" onClick={() => saveData(asideData)}>
                        Save
                        </raul-button>
                    </raul-aside-actions>
                </raul-aside-footer>
            </raul-aside>

            <div class="r-mt-8">
                <raul-grid hoverable>
                    <raul-grid-header> 
                        <raul-grid-row>
                            <raul-grid-cell class="r-w-2/3">
                                Property Name
                                <raul-grid-sorter onClick={() => companySort("propertyName")}></raul-grid-sorter>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/3">
                                City
                                <raul-grid-sorter onClick={() => companySort("cityName")}></raul-grid-sorter>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/3">
                                State
                                <raul-grid-sorter onClick={() => companySort("stateName")}></raul-grid-sorter>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/4 r-text-center">
                                Channels
                                <raul-grid-sorter onClick={() => companySort("channelList")}></raul-grid-sorter>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-2/5 r-text-center">
                                Sending Images
                                <raul-grid-sorter></raul-grid-sorter>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/3 r-text-center">
                                Send Ready
                                <raul-grid-sorter></raul-grid-sorter>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/2 r-text-center">
                                Marketing Status
                                <raul-tooltip class="r-text" text="Marketing Status signals whether the property has any active floor plans.<br/> <br/> Not having any floor plans would prevent listings from syndicating." placement="top">
                                    <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                                </raul-tooltip>
                                <raul-grid-sorter></raul-grid-sorter>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/2">
                                Syndication Data
                                <raul-icon icon="interface-information" class="r-icon-xl r-ml-3 r-cursor-pointer" onClick={() => helpfultips()}></raul-icon>
                                <raul-grid-sorter></raul-grid-sorter>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/6"></raul-grid-cell>
                        </raul-grid-row>
                    </raul-grid-header>
                    <raul-grid-body> { propertyList.map(property => (
                        <raul-grid-row key={property.propertyId}>
                            <raul-grid-cell class="r-w-2/3">
                                <div className="r-cursor-pointer r-text-primary" onClick={() => props.changeDashboardProperty(property.pkey)}>{property.propertyName}</div>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/3">
                                <div>{property.cityName}</div>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/3">
                                {property.stateName}
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/4 r-text-center">
                                <raul-popover placement="top">
                                    <raul-popover-container slot="popover">                              
                                        <raul-popover-content slot="popover-content" class="r-m-auto r-pr-4">
                                            <p slot="label" class="r-text-lg r-text-left r-font-bold r-pr-3 r-pt-1 r-pb-1">ILS Channels</p>
                                            <p slot="sub-label" class="r-text-md r-text-left r-font-normal r-pr-3">
                                            {property.channelList && property.channelList.map((channel,index) => (
                                                <span class="r-block  r-pt-1 r-pb-1" key={index}>{channel.leadChannelName}</span>
                                            ))}
                                             </p>
                                        </raul-popover-content>
                                    </raul-popover-container>
                                    <div className="r-text-primary r-cursor-pointer">{property.channelList ? property.channelList.length : 0}</div>
                                </raul-popover>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-2/5 r-text-center">
                                <div class="raul-status">
                                    <div className ={property.syndicateMedia === 'true' ? 'r-status r-status--success' : 'r-status r-status--destructive'}>
                                        <div title={property.syndicateMedia === 'true' ? 'Yes' : 'No'}>{property.syndicateMedia === 'true' ? 'Yes' : 'No'}</div>
                                    </div>
                                </div>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/3 r-text-center">
                                <div class="raul-status">
                                    <div className ={property.syndicationReady === 'true' ? 'r-status r-status--success' : 'r-status r-status--destructive'}>
                                        <div title={property.syndicationReady === 'true' ? 'Yes' : 'No'}>{property.syndicationReady === 'true' ? 'Yes' : 'No'}</div>
                                    </div>
                                </div>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/2 r-text-center">
                                <div class="raul-status">
                                    <div className ={property.marketReady === 'true' ? 'r-status r-status--success' : 'r-status r-status--destructive'}>
                                        <div title={property.marketReady === 'true' ? 'On' : 'Off'}>{property.marketReady === 'true' ? 'On' : 'Off'}</div>
                                    </div>
                                </div>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/2">
                                <raul-progress
                                    static
                                    value={ property.completenessScore.propertyScore }
                                    color={ property.completenessScore.propertyScore < 51 ? "danger" :
                                            property.completenessScore.propertyScore < 90 ? "warning" : "success" }
                                ></raul-progress>
                            </raul-grid-cell>
                            <raul-grid-cell class="r-w-1/6">
                                <div className="r-cursor-pointer">
                                    <raul-icon icon="pencil" kind="consumer" id="small" class="aside-show-button r-icon-2xl" onClick={() => openEdit(property)}></raul-icon>
                                </div>
                            </raul-grid-cell>
                        </raul-grid-row>
                    ))} </raul-grid-body>
                </raul-grid>
                <raul-paging-bar total-rows={props.propertyList.length} class="r-font-medium" ref={pageRef}></raul-paging-bar>
                
                <raul-aside id="tips-tricks-aside" size="large" ref={tipsTricksAside}>
                    <raul-aside-header class="r-bg-gray-lightest">
                        <raul-aside-title><span>Tips & Tricks</span>
                        <raul-button variant = "control" size = "small" id="tipsExport" class="r-float-right r-mt-0" onClick={()=>syndDataImprovementGraphicExportAsPdf(imageRef, loader)} >
                            <raul-icon icon="download-bottom" kind ="consumer" class="r-icon-sm"></raul-icon>
                            Export
                        </raul-button>
                    </raul-aside-title>

                    </raul-aside-header>

                    <raul-aside-body class="r-pl-0">
                        <img src="images/SyndDataImprovementGraphic.png" alt="BigCo Inc. logo" ref={tipsImageRef} />
                    </raul-aside-body>

                    <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-button class="nested-aside-close" variant="secondary" id="tips-tricks-aside-close" ref={tipsTricksCloseRef}>
                        Close
                        </raul-button>
                    </raul-aside-actions>
                    </raul-aside-footer>
                </raul-aside>
            </div>
        </div>
    )
}

export default TableCompany;