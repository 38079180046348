import { useEffect, useRef, useState, useContext } from "react";
import AppConfig from "../../app-config";
import { appContext, loaderContext } from "../../App";
import { exportMissingFileds, syndDataImprovementGraphicExportAsPdf } from "../../service/utils";

const SyndData = (props) => {
    const { http } = useContext(appContext);
    const loader  = useContext(loaderContext);
    const asideRef = useRef();
    const closeRef = useRef();
    const helpAside = useRef();
    const imageRef = useRef();
    const tipsTricksAside = useRef();
    const tipsTricksCloseRef = useRef();
    const [message,setMessage] = useState("");
    const [isSaved,setIsSaved] = useState(false);
  
    useEffect(() => {
        closeRef.current.addEventListener('click', function() {
            asideRef.current.close();
          });
        helpAside.current.addEventListener('click', function() {
            setIsSaved(false);
            tipsTricksAside.current.open();
        });
        tipsTricksCloseRef.current.addEventListener('click', function() {
            tipsTricksAside.current.close();
        });
        setIsSaved(false);
    }, []);

  const getMissingFieldCount = (property) => {
    if (property && property.completenessScore && property.completenessScore.missingFieldList) {
        return property.completenessScore.missingFieldList.length;
    }
    return 0;
    };
    const missingFieldLinks = {
        other: props.mcUrl+"property/{pkey}/description",
        marketingPhotos: props.mcUrl+"property/{pkey}/gallery-view",
        propAndFpAmenity: props.mcUrl+"property/{pkey}/amenity/view",
        fpDiagramPhotosVTours: props.mcUrl+"floorplans/{pkey}",
        addFloorplan:props.mcUrl+"floorplans/{pkey}"
    }
    
    const missingFieldUrl = (pkey, name) => {
        var url = "";
        if (["Active Floorplan","Floorplan Photo", "Floorplan Diagram", "Floorplan Video"].includes(name))
            url = missingFieldLinks.fpDiagramPhotosVTours.replace('{pkey}', pkey);
        else if (["Floorplan Amenity" , "Property Amenity"].includes(name))
            url = missingFieldLinks.propAndFpAmenity.replace('{pkey}', pkey);
        else if (name === "Property Marketing Photos")
            url = missingFieldLinks.marketingPhotos.replace('{pkey}', pkey);
        else if (name === "Add Floor Plans")
            url = missingFieldLinks.addFloorplan.replace('{pkey}', pkey);
        else
            url = missingFieldLinks.other.replace('{pkey}', pkey);
        window.open(url, '_blank', 'noopener');
    };

    const sentEmailNotification = (data) => {
        var url =  AppConfig.uri.sendEmailNotification;
        var requestBody = {
            propertyId: data.propertyId,
            score: props.propertyData?.completenessScore?.propertyScore
        }
        http.callApi('POST', url, [requestBody])
        .then((response) => {
            setIsSaved(true);
            props.propertyData.lastEmailSent = response[0].lastSent;
            props.propertyData.isDisabled = true;
            setMessage('Email sent successfully to selected property.');
        }).catch((error) => {
            setIsSaved(true);
            setMessage("Error while Email sent not successfully to selected property..");
        });
    }

    const missingDataChangeEvent = (type) => {
        if(type === 'true') {
            setIsSaved(false);
            asideRef.current.open();
        } else {
            missingFieldUrl(props.propertyData.pkey, "Add Floor Plans");
        }

    }

    return (
        <div class="missing-data-container syndication-data">
            <raul-container>
                <div class="r-flex">
                    {
                        props.propertyData?.marketReady === 'true' ?
                        <>
                        <div class="content-text r-w-1/2">
                            <p class="r-text-lg r-font-bold">Syndication Data</p>
                            <p class="r-text-md">Required fields for optimal performance</p>
                        </div>
                        <div class="r-w-1/4">
                            <raul-progress
                                label="Syndication Data"
                                hint="Completed"
                                dynamic
                                color={ props.propertyData.completenessScore.propertyScore < 51 ? "danger" :
                                        props.propertyData.completenessScore.propertyScore < 90 ? "warning" : "success" }
                                value={props.propertyData.completenessScore.propertyScore}
                            ></raul-progress>
                        </div>
                        </>
                        :
                        <>
                        <raul-icon icon="alert" class="r-text-destructive r-text-hero r-pr-3"></raul-icon>
                        <div class="content-text r-w-3/4">
                            <p class="r-text-lg r-font-bold">Marketing Status : Off</p>
                            <p class="r-text-md">This property does not have active floorplans and hence it will prevent the listings from syndicating.</p>
                        </div>
                        </>
                    }
                    <div class="r-w-1/4">
                        {
                            props.propertyData?.marketReady === 'true' &&
                            <raul-button variant="control" size="small" class="r-float-right" onClick={() => missingDataChangeEvent(props.propertyData?.marketReady)}>Update Missing Data</raul-button>
                        }
                        {
                            props.propertyData?.marketReady === 'false' &&
                            <raul-button variant="control" size="small" class="r-float-right" onClick={() => missingDataChangeEvent(props.propertyData?.marketReady)} target="_blank">Add Floor Plans</raul-button>
                        }
                    </div>
                </div>
            </raul-container>
            <raul-aside id="missing-data-aside" size="medium" ref={asideRef}>   
                <raul-aside-header> 
                    <raul-aside-title class="r-font-bold">
                        <div class="r-flex">
                            <div class="r-w-2/3">
                                <p class="r-mb-1 r-text-sm">{props.propertyData.propertyName}</p>
                                <p class="r-text-black">Missing Syndication Data</p>
                            </div>
                            <div class="r-w-1/2">
                                <raul-button variant = "control" size = "small" id="missingExport" class="r-float-right r-mt-0"  onClick={() => exportMissingFileds(props.propertyData, 'property')} >
                                    <raul-icon icon="download-bottom" kind ="consumer" class="r-icon-sm"></raul-icon>
                                    Export
                                </raul-button>
                                <div class="r-float-right r-text-primary r-mr-2 r-text-sm r-p-2 nested-aside-show-button r-cursor-pointer" ref={helpAside}  >
                                        <raul-icon icon="help-center" kind="resource" class="r-text-md r-mr-2"></raul-icon>
                                        Helpful Tips
                                </div>                                  
                            </div>
                        </div>
                    </raul-aside-title>
                </raul-aside-header>
                <raul-aside-body class="r-pr-1">
                     
                        { !props.subscriptionEnable &&
                            <p class="r-font-roboto r-text-sm r-pt-3 r-mb-5 ">To subscribe to Syndication Data email notifications, select the user profile icon in the top right corner of the page header and navigate to Email Preferences.</p>   
                        }                  
                        <div class="r-flex r-mb-3">
                            <raul-button variant="control" class="r-pb-4" className={props.propertyData.isDisabled ? '' : 'r-cursor-pointer'} size="small" onClick={() => props.propertyData.isDisabled ? '' : sentEmailNotification(props.propertyData)}  disabled = {props.propertyData.isDisabled || !props.subscriptionEnable}>Send Email Notification</raul-button>
                            <raul-tooltip class="r-text r-cursor-pointer  r-text-black r-pb-2" text='<p>If an email was sent in the last 24 hours, the option to re-send will be disabled for this property.</p><br/>' placement="bottom">
                                <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                            </raul-tooltip>
                            { !props.subscriptionEnable &&
                            <div className="missing-email-alert r-pl-4">
                                <raul-alert class="r-font-bold"
                                    content="No Email Subscribers (See Above)"
                                    rounded
                                    variant="warning"
                                ></raul-alert>
                            </div>
                            }
                        </div>
                        {isSaved && 
                            <div class="missing-email-alert r-pb-4 r-pr-4">
                                <raul-alert class="r-font-bold" content={message} rounded variant={message.includes("Error") ? "danger" : "success"}/>
                            </div>
                        }
                    <div class="aside-missing-data">
                        <>
                            <div class="r-flex">
                                <raul-icon icon="alert" class="r-text-destructive r-text-hero r-pr-2"></raul-icon>
                                <div class="content-text">
                                    <p class="r-text-md r-font-semibold">Required fields for optimal syndication performance.</p>
                                    <p class="r-text-md">You have ({ getMissingFieldCount(props.propertyData) }) missing fields.</p>
                                </div>
                            </div>
                            <div className="aside-missing-data-margin">
                                <div class="r-flex r-mb-2">
                                    <p class="r-text-md r-w-1/2">The following fields are missing.</p>
                                    <p class="r-text-md r-w-1/2">Last Email Sent: {props.propertyData.lastEmailSent}.</p>
                                </div>
                                <ul class="two-column-list r-pl-4"> { props.propertyData.completenessScore.missingFieldList.map((item,index) =>(
                                    <li class="r-text-primary" key={index}><div className="r-cursor-pointer" onClick={() => missingFieldUrl(props.propertyData.pkey, item)} target="_blank">{item}</div></li>
                                ))}</ul>   
                            </div>
                        </>
                    </div>
                </raul-aside-body>
                <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-aside-actions>
                            <raul-button class="close-aside" variant="primary" id="missing-data-aside-close" ref={closeRef}>
                                Close
                            </raul-button>           
                        </raul-aside-actions>
                    </raul-aside-actions>
                </raul-aside-footer>
                <raul-aside id="tips-tricks-aside" slot="secondary-aside" size="large"  ref={tipsTricksAside} >
                    <raul-aside-header class="r-bg-gray-lightest">
                        <raul-aside-title><span>Tips & Tricks</span>
                        <raul-button variant = "control" size = "small" id="tipsExport" class="r-float-right r-mt-0" onClick={() => syndDataImprovementGraphicExportAsPdf(imageRef, loader)} >
                            <raul-icon icon="download-bottom" kind ="consumer" class="r-icon-sm"></raul-icon>
                            Export
                        </raul-button>
                    </raul-aside-title>
        
                    </raul-aside-header>

                    <raul-aside-body class="r-pl-0">
                        <img src="images/SyndDataImprovementGraphic.png" alt="BigCo Inc. logo" ref={imageRef} />
                    </raul-aside-body>

                    <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-button class="nested-aside-close" variant="secondary" id="tips-tricks-aside-close" ref={tipsTricksCloseRef} >
                        Close
                        </raul-button>
                    </raul-aside-actions>
                    </raul-aside-footer>
                </raul-aside>          
            </raul-aside>
        </div>
    );
};

export default SyndData;
